import React, { useContext } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { AppContext, PlayerContext } from '../../context';
import './style.scss';
import { database } from '../../utilities/database';

interface Props {
  intl: InjectedIntl;
  teamName?: string;
}

const WelcomeMessageWithLogout: React.FC<Props> = ({ intl, teamName }) => {
  const { session } = useContext(AppContext);
  const { game } = useContext(PlayerContext);

  const logout = async (evt: any) => {
    evt.preventDefault();
    
    await database.set(session!.data.sessionDataId!,  // update arena status
      `games.${game!.token}`, {
        ...game,
        arenaStatus: 'completed'
    });
    window.location.href = `${window.BASEPATH}/logout`
  }

  return (
    <div className='WelcomeMessageWithLogout'>
      <div className="info">
        <span className="welcome">
          {intl.formatMessage({ id: 'welcome-team:label' })}
        </span>
        {` `}
        <span className="team">
          <b>
            {game && game.team ? game.team : teamName ? teamName : ''}
          </b>
        </span>
      </div>
      <a
        onClick={logout} href="#"
        className="button-empty-blu logout">
        {intl.formatMessage({ id: 'logout:label' })}
      </a>
    </div>
  );
};

export default injectIntl(WelcomeMessageWithLogout);
