import { formatRoute } from 'react-router-named-routes';
import {
  BrowserRouter,
  NavLink,
  Route,
  withRouter,
  Switch,
  Redirect,
  matchPath
} from 'react-router-dom';

const root = '/';
const enroll = '/enroll';
const onboarding = '/onboarding/:gameSessionId/:token';

const player = '/player/:gameSessionId/:token';
const report = `${player}/report`;
const thankyou = `${player}/thankyou`;
const chapter = `${player}/chapter/:chapterID`;

const admin = '/admin';
const adminPanelRedirect = '/admin/session/:gameSessionId/panel';
const adminOverviewRedirect = '/admin/session/:gameSessionId/overview';

const adminWithUser = '/admin/:userId';
const adminSessions = `${adminWithUser}/sessions`;
const adminSessionsWithSelection = `${adminWithUser}/sessions/:gameSessionId`;
const adminSession = `${adminWithUser}/session/:gameSessionId`;

const adminPanel = `${adminSession}/panel`;
const adminTimeline = `${adminSession}/timeline`;
const adminOverview = `${adminSession}/overview`;
const adminPanelTeam = `${adminPanel}/:teamID`;
const adminPanelTeamEdit = `${adminPanel}/:teamID/edit`;
const adminOverviewChapter = `${adminOverview}/:chapterID`;

const routes: { [s: string]: string } = {
  root,
  enroll,
  thankyou,
  report,
  player,
  admin,
  adminPanelRedirect,
  adminOverviewRedirect,
  adminWithUser,
  chapter,
  onboarding,
  adminPanel,
  adminPanelTeam,
  adminPanelTeamEdit,
  adminTimeline,
  adminOverview,
  adminSession,
  adminSessions,
  adminOverviewChapter,
  adminSessionsWithSelection
};

export {
  BrowserRouter,
  NavLink,
  Route,
  withRouter,
  Switch,
  Redirect,
  matchPath
};
export default function (routeName: string, routeProps = {}) {
  const selectedRoute = routes[routeName];
  if (!selectedRoute) {
    throw new Error(`Can't find route for route name '${routeName}'`);
  }
  return formatRoute(selectedRoute, routeProps);
}
