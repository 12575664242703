import striptags from 'striptags';
import { IPlayer } from '../interfaces';

const generateReportMail = (
  intl: any,
  player: IPlayer,
  reportLink: string,
  excelModelUrl: string,
  team: string
) => {
  const from = 'SDA Bocconi Learning Lab <learninglab@sdabocconi.it>';
  const bcc = 'no-reply.learninglab@sdabocconi.it';
  const subject = `${intl.formatMessage({ id: `email-subject` })}${team}${intl.formatMessage({ id: `email-subject-b` })}`;
  console.log('player.firstName', player.firstName);
  console.log('player.lastName', player.lastName);
  console.log('reportLink', reportLink);
  const html = intl.formatMessage(
    {
      id: `email-body`
    },
    {
      name: `${player.firstName} ${player.lastName}`,
      report: reportLink
    }
  );
  const text = striptags(html);
  console.log('subject', subject);
  console.log('html', html);
  console.log('text', text);
  return {
    from,
    bcc,
    to: player.email,
    subject,
    text,
    html
  };
};

const generatePhase4OfferMail = (
  intl: any,
  player: IPlayer
) => {
  const from = 'SDA Bocconi Learning Lab <learninglab@sdabocconi.it>';
  const bcc = 'no-reply.learninglab@sdabocconi.it';
  const subject = intl.formatMessage({ id: `email-phase4Offer-subject` });
  const reportLink = '';
  const excelModelUrl = '';
  const partialHtml = intl.formatMessage(
    {
      id: `email-phase4Offer-body`
    },
    {
      name: player.firstName,
      report: reportLink,
      excel: excelModelUrl
    }
  );

  const html = partialHtml.concat(intl.formatMessage({id: 'email-phase4Offer-popup-signature'}))

  const text = striptags(html);
  console.log('subject', subject);
  console.log('html', html);
  console.log('text', text);
  return {
    from,
    bcc,
    to: player.email,
    subject,
    text,
    html
  };
};

export { generateReportMail, generatePhase4OfferMail };
