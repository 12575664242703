import { AssetQueryOutput } from "./api";
import { IUserInfo } from "./utilities/auth-services";

export interface IRouteMatch {
  params: any;
  history: any;
  location: any;
}

export interface IScenarioKpi {
  index?: number;
  scenarioID: string;
  kpiID: string;
  value?: number;
  correctValue?: number | number[];
  updatedAt?: string;
  retries: number;
  status: EKpiStatus;
  rowIndex: number;
  columnIndex: number;
  chapterIndex: number;
  output?: boolean;
}

export interface IKpi {
  id: string;
  index?: number;
  label?: string;
  unit: string;
  completed?: boolean;
  active?: boolean;
  notes?: string;
  notesConfirmed?: boolean;
  dependOn?: string[];
  show?: boolean;
}

export interface IScenario {
  id: string;
  index?: number;
  label?: string;
  completed?: boolean;
  active?: boolean;
  notes?: string;
  notesConfirmed?: boolean;
  dependOn?: string[];
  show?: boolean;
  isLocked?: boolean;
}

export interface IAsset {
  id: string;
  index?: number;
  kind: string;
  url: string;
  previewImage?: string;
  active: boolean;
  isLocked?: boolean;
  urlEng:string
  name: string,
  nameEng: string
}

export interface IChapter {
  id: string;
  index?: number;
  kind: EChapterKind;
  activeAt?: string;
  completed?: boolean;
  completedAt?: string;
  assets: IAsset[];
  scenarios: IScenario[];
  kpis: IKpi[];
  matrix: (IScenarioKpi | null)[][];
  originalMatrix?: (IScenarioKpi | null)[][];
  excelUpload?: boolean;
  excelUrl?: string;
  pptUpload?: boolean;
  pptUrl?: string;
  viewed: boolean;
}

export interface IPlayer {
  firstName: string;
  lastName: string;
  email: string;
}

export interface IGame {
  id: string;
  session: string;
  token: string;
  team: string;
  sended: boolean;
  initialized: boolean;
  players: IPlayer[];
  excelModelUrl: string;
  chapters: IChapter[];
  phase4OfferSended?: boolean;
  phase4ModalVisible?: boolean;
  arenaStatus: string;
}

export enum EChapterKind {
  PROLOGUE = 'PROLOGUE',
  SCENARIOS_MATRIX = 'SCENARIOS_MATRIX',
  KPIS_MATRIX = 'KPIS_MATRIX',
  EPILOGUE = 'EPILOGUE'
}

export enum EKpiStatus {
  EMPTY = 'EMPTY',
  CORRECT = 'CORRECT',
  WRONG = 'WRONG'
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
}

//////////////////////////////////////////////////////// SESSION INTERFACE

type SessionAsset = Omit<AssetQueryOutput, 'data' | '__typename'> & {
  data: { 
    lang: string,
    teachers: string[],
    paramId?: string,
    paramLabel?: string,
    sessionDataId?: string,
    teamNo: number
    terminated?: boolean
  }
  __typename?: string
}

export interface ISession extends SessionAsset { }

export type ParamWithOwner = Omit<AssetQueryOutput, 'owner'> & { 
  owner?: IUserInfo 
}
