import React, { useEffect, useState } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  default as ReactResponsive
} from 'react-responsive';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import AdminProvider from './components/AdminProvider';
import Login from './components/Login';
import LowRes from './components/LowRes';
import OnboardingController from './components/OnboardingController';
import PlayerProvider from './components/PlayerProvider';
import { AppContext } from './context';
import en from './dictionaries/en';
import it from './dictionaries/it';
import route, { BrowserRouter, Redirect, Route, Switch } from './route';
import { ISession } from './interfaces';
import { assetsService } from './utilities/assetsService';
import { AssetType } from './api';
import * as localeit from 'react-intl/locale-data/it';
import { UserAuth } from './utilities/auth-services';

addLocaleData(localeit);

const langs: { [key: string]: any } = { it: it, en: en };
const App: React.FC = () => {
  const [lang, setLang] = useState<string>('it');
  const [session, setSession] = useState<ISession>();

  useEffect(() => {
    if (!session) return ;  // noop

    const subscription = assetsService.onAssetsUpdate({ 
        type: AssetType.session, 
        groupId: session!.groupId
      },
      (mutation) => {
        const newSession: ISession = JSON.parse(mutation.data);
        delete newSession.__typename; 
        setSession!(newSession);
      }
    );
    return () => subscription?.unsubscribe()
  }, 
  [session, setSession])

  useEffect(() => {
    setLang(session ? session.data.lang : 'it');
  }, 
  [session]);

  return (
    <AppContext.Provider value={{ lang, setLang, session, setSession }}>
      <IntlProvider locale={lang} messages={langs[lang]}>
        <div className="App">
          <ReactResponsive query="(min-width: 601px)">
            <BrowserRouter basename={process.env.REACT_APP_BASEPATH || ''}>
              <Switch>
                <Route path={route('enroll')} component={Login} />
                <Route path={route('player')} component={PlayerProvider} />
                <Route
                  path={route('onboarding')}
                  component={OnboardingController}
                />
                <Route path={route('adminPanelRedirect')} render={props => {
                  return (
                    <Redirect to={route('adminPanel', { 
                      userId: UserAuth.getUserData().id,
                      ...props.match.params
                    })} />
                  );
                }}/>
                <Route path={route('adminOverviewRedirect')} render={props => {
                  return (
                    <Redirect to={route('adminOverview', { 
                      userId: UserAuth.getUserData().id,
                      ...props.match.params
                    })} />
                  );
                }}/>
                <Route path={route('adminSession')}  component={AdminProvider} />
                <Route path={route('adminWithUser')} component={AdminProvider} />
                <Route path={route('admin')} render={(..._) => {
                    return (
                      <Redirect to={route('adminSessions', { 
                        userId: UserAuth.getUserData().id
                      })} />
                    );
                  }}
                />
                <Redirect to={route('enroll')} />
              </Switch>
            </BrowserRouter>
          </ReactResponsive>
          <ReactResponsive query="(max-width: 600px)">
            <LowRes />
          </ReactResponsive>
        </div>
      </IntlProvider>
    </AppContext.Provider>
  );
};

export default App;

declare global {
  interface Window {
    BASEPATH: string;
  }
}
